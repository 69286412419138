import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import PdfButton from "../components/PdfButton"
import {
  certificationESGTest,
  requirementsESG,
  esgDocuments,
} from "../data/certification-efp"
import Iva from "../components/Iva"
import parse from "react-html-parser"
import TitlePage from "../components/TitlePage"
import { useAxiosClient } from "../service/useAxiosClient"
import AnnouncementEfp from "../components/Announcement/AnnouncementEfp"
import TutorialBtn from "../components/TutorialBtn"

export default function CertificationEsg({ location }) {
  const id = 11
  const [test, setTest] = useState()

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificacion" },
    {
      label: " Exámenes certificación EFPA ESG <em>Advisor</em>™",
    },
  ]
  const [price, setPrice] = useState()
  const [{ data: dataPrice }] = useAxiosClient({
    url: `/professional-certificates/web/exam-price/?professional_certificate=${id}`,
    method: "GET",
  })

  const [{ data: dataTest }] = useAxiosClient({
    url: `/exams/dates/web/next-calls/?exam_type=ESG`,
    method: "GET",
  })
  useEffect(() => {
    if (dataTest && dataTest.ESG) {
      const [test] = dataTest.ESG.calls
      setTest(test)
    }
  }, [dataTest])

  useEffect(() => {
    if (dataPrice) {
      setPrice(dataPrice)
    }
  }, [dataPrice])

  const renderNextTest = () => {
 
    return (
      test && (
        <div className="Certification-content">
          <AnnouncementEfp
            title={test?.call_text}
            date={test?.exams_date_text}
            link={test?.inscription_link}
          />
        </div>
      )
    )
  }

  const renderContent = () => {
    return (
      certificationESGTest &&
      certificationESGTest.map((item, i) => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-container">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }
  const renderContentRight = () => {
    return (
      requirementsESG &&
      requirementsESG.map(item => {
        return (
          <div className="efpa-content">
            <TitleBlock
              className="Certification-title-block"
              title={item.title}
              icon={item.icon}
            />
            <div className="Certification-container">{parse(item.content)}</div>
          </div>
        )
      })
    )
  }
  const renderDocuments = () => {
    return (
      esgDocuments &&
      esgDocuments.map(item => {
        return (
          <PdfButton
            isExternal
            className="mb-2"
            text={item.name}
            link={item.link}
          />
        )
      })
    )
  }
  return (
    <Layout>
      <TitlePage title="EFPA ESG Advisor™" url={location.pathname} />
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Certificación EFPA ESG <em>Advisor</em>™"
          icon="fab fa-envira"
        />
        <div className="CertificationTestEFP">
          <div className="row efpa-row">
            <div className="col-12 col-md-6 Certification-padding-r">
              {renderContent()}
              <TitleBlock
                className="Certification-title-block"
                title="Simulación examen"
                icon="fas fa-file-alt"
              />
              <PdfButton
                isExternal
                parseText
                link="/docs/Examen-mock-on-line-EFPA-ESG-Advisor.pdf"
                text="Simulación examen ESG <em>Advisor</em>"
              />
              <TutorialBtn />
            </div>
            <div className="col-12 col-md-6 Certification-padding-l">
              {renderContentRight()}
              <div className="Certification-content efpa-content">
                <TitleBlock
                  className="Certification-title-block"
                  title="Documentación"
                  icon="fas fa-file-alt"
                />
                {renderDocuments()}
              </div>
              {renderNextTest()}
              <div className="efpa-content">
                <TitleBlock
                  className="Certification-title-block mt-3"
                  title="Coste"
                  icon="d-none"
                />
                <Iva price={`${price} € (IVA incluido)`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
